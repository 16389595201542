import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import { NgbDateParserFormatter, NgbModule, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NouisliderModule } from 'ng2-nouislider';
import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { SimplebarAngularModule } from 'simplebar-angular';
import Swal from 'sweetalert2';
import { SwiperModule } from 'swiper_angular';
import { environment } from '../environments/environment';
import { SidenavModule } from '../vendor/libs/sidenav/sidenav.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutRestaurantComponent } from './components/admin/about-restaurant/about-restaurant.component';
import { LocationAddModalComponent } from './components/admin/about-restaurant/location-add-modal/location-add-modal.component';
import { LocationDeleteModalComponent } from './components/admin/about-restaurant/location-delete-modal/location-delete-modal.component';
import { RestaurantEditModalComponent } from './components/admin/about-restaurant/restaurant-edit-modal/restaurant-edit-modal.component';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { AdditionsEditModalComponent } from './components/admin/menu-table/additions-edit-modal/additions-edit-modal.component';
import { OptionsEditComponent } from './components/admin/menu-table/additions-edit-modal/options-edit/options-edit.component';
import { TagsEditComponent } from './components/admin/menu-table/additions-edit-modal/tags-edit/tags-edit.component';
import { CategoryEditComponent } from './components/admin/menu-table/category-edit/category-edit.component';
import { MenuTableComponent } from './components/admin/menu-table/menu-table.component';
import { OrdersModalComponent } from './components/admin/orders/orders-modal/orders-modal.component';
import { OrdersComponent } from './components/admin/orders/orders.component';
import { PlansComponent } from './components/admin/plans/plans.component';
import { RestaurantEditComponent } from './components/admin/restaurant-edit/restaurant-edit.component';
import { BasketModalComponent } from './components/basket/basket-modal/basket-modal.component';
import { DineInModalComponent } from './components/basket/basket-modal/dine-in-modal/dine-in-modal.component';
import { PhoneModalComponent } from './components/basket/basket-modal/phone-modal/phone-modal.component';
import { PickUpModalComponent } from './components/basket/basket-modal/pick-up-modal/pick-up-modal.component';
import { TaxFeeModalComponent } from './components/basket/basket-modal/tax-fee-modal/tax-fee-modal.component';
import { BasketComponent } from './components/basket/basket.component';
import { BrowserSupportComponent } from './components/browser-support/browser-support.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { DivRectangleComponent } from './components/elements/div-rectangle/div-rectangle.component';
import { HeartComponent } from './components/elements/heart/heart.component';
import { ImageCropperComponent } from './components/elements/image-cropper/image-cropper.component';
import { ImagePickerComponent } from './components/elements/image-picker/image-picker.component';
import { ImageSelectModalComponent } from './components/elements/image-picker/image-select-modal/image-select-modal.component';
import { PhantomComponent } from './components/elements/phantom/phantom.component';
import { PickerModalComponent } from './components/elements/picker/picker-modal/picker-modal.component';
import { PickerComponent } from './components/elements/picker/picker.component';
import { PostOptionsComponent } from './components/elements/post-options/post-options.component';
import { RestaurantsPickerComponent } from './components/elements/restaurants-picker/restaurants-picker.component';
import { EmbedVideoComponent } from './components/embed-video/embed-video.component';
import { ReportVideoComponent } from './components/embed-video/report-video/report-video.component';
import { DistanceFilterComponent } from './components/explore/distance-filter/distance-filter.component';
import { ExploreComponent } from './components/explore/explore.component';
import { SearchCityInputComponent } from './components/explore/search-city/search-city-modal/search-city-input/search-city-input.component';
import { SearchCityModalComponent } from './components/explore/search-city/search-city-modal/search-city-modal.component';
import { SearchCityComponent } from './components/explore/search-city/search-city.component';
import { DividerMobileModalComponent } from './components/explore/search-desktop/divider-mobile-modal/divider-mobile-modal.component';
import { DividerModalComponent } from './components/explore/search-desktop/divider-modal/divider-modal.component';
import { SearchDesktopLoaderComponent } from './components/explore/search-desktop/search-desktop-loader/search-desktop-loader.component';
import { SearchDesktopTopnavComponent } from './components/explore/search-desktop/search-desktop-topnav/search-desktop-topnav.component';
import { SearchDesktopComponent } from './components/explore/search-desktop/search-desktop.component';
import { SearchFiltersComponent } from './components/explore/search-filters/search-filters.component';
import { SearchMobileLoaderComponent } from './components/explore/search-mobile-loader/search-mobile-loader.component';
import { ChainModalDesktopComponent } from './components/explore/search-mobile/chain-modal-desktop/chain-modal-desktop.component';
import { OwnerVerificationComponent } from './components/explore/search-mobile/owner-verification/owner-verification.component';
import { InstagramHelperComponent } from './components/explore/search-mobile/preview-modal/instagram-helper/instagram-helper.component';
import { PreviewModalFooterComponent } from './components/explore/search-mobile/preview-modal/preview-modal-footer/preview-modal-footer.component';
import { PreviewModalSlideComponent } from './components/explore/search-mobile/preview-modal/preview-modal-slide/preview-modal-slide.component';
import { PreviewModalComponent } from './components/explore/search-mobile/preview-modal/preview-modal.component';
import { RestaurantAddModalComponent } from './components/explore/search-mobile/restaurant-add-modal/restaurant-add-modal.component';
import { SearchMobileComponent } from './components/explore/search-mobile/search-mobile.component';
import { SearchModalFavoritesNavbarTopComponent } from './components/explore/search-mobile/search-modal-favorites-slide/search-modal-favorites-navbar-top/search-modal-favorites-navbar-top.component';
import { SearchModalFavoritesSlideComponent } from './components/explore/search-mobile/search-modal-favorites-slide/search-modal-favorites-slide.component';
import { SearchModalFooterComponent } from './components/explore/search-mobile/search-modal-footer/search-modal-footer.component';
import { SearchModalComponent } from './components/explore/search-mobile/search-modal/search-modal.component';
import { AddToHomeScreenModalComponent } from './components/explore/search-mobile/search-restaurant/add-to-home-screen-modal/add-to-home-screen-modal.component';
import { SearchRestaurantListComponent } from './components/explore/search-mobile/search-restaurant/search-restaurant-list.component';
import { VideoDesktopModalComponent } from './components/explore/search-mobile/video-desktop-modal/video-desktop-modal.component';
import { SearchVideoModalComponent } from './components/explore/search-mobile/video-modal/search-video-modal.component';
import { VideoReportModalComponent } from './components/explore/search-mobile/video-report-modal/video-report-modal.component';
import { FavoriteFoodImageComponent } from './components/favorites/favorite-food-image/favorite-food-image.component';
import { FavoritesListComponent } from './components/favorites/favorites-list/favorites-list.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { FlashReceiptComponent } from './components/flash-receipt/flash-receipt.component';
import { ReceiptModalComponent } from './components/flash-receipt/receipt-modal/receipt-modal.component';
import { HomeComponent } from './components/home/home.component';
import { FoodImgComponent } from './components/images/food-img/food-img.component';
import { RestaurantImgComponent } from './components/images/restaurant-img/restaurant-img.component';
import { InstagramComponent } from './components/instagram/instagram.component';
import { LayoutAdminLocationComponent } from './components/layout/layout-main/layout-admin/layout-admin-location/layout-admin-location.component';
import { LayoutAdminWithoutLocationComponent } from './components/layout/layout-main/layout-admin/layout-admin-without-location/layout-admin-without-location.component';
import { LayoutAdminComponent } from './components/layout/layout-main/layout-admin/layout-admin.component';
import { LayoutEntityComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-entity.component';
import { LayoutFoodComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-menu/layout-food/layout-food.component';
import { LayoutMenuComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-menu/layout-menu.component';
import { LayoutRestaurantLocationComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant-location/layout-restaurant-location.component';
import { LayoutRestaurantNoLocationComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant-no-location/layout-restaurant-no-location.component';
import { LayoutRestaurantComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-restaurant/layout-restaurant.component';
import { LayoutUserComponent } from './components/layout/layout-main/layout-front/layout-entity/layout-user/layout-user.component';
import { LayoutFrontComponent } from './components/layout/layout-main/layout-front/layout-front.component';
import { LayoutMainComponent } from './components/layout/layout-main/layout-main.component';
import { LayoutSettingsComponent } from './components/layout/layout-main/layout-settings/layout-settings.component';
import { LayoutSidenavLeftComponent } from './components/layout/layout-sidenav-left/layout-sidenav-left.component';
import { LayoutSidenavRightComponent } from './components/layout/layout-sidenav-right/layout-sidenav-right.component';
import { LikeComponent } from './components/like/like.component';
import { MiniContactComponent } from './components/mini-contact/mini.contact.component';
import { DesktopModalSidenavComponent } from './components/modals/desktop-modal-sidenav/desktop-modal-sidenav.component';
import { EmailModalComponent } from './components/modals/email-modal/email-modal.component';
import { HoursEditModalComponent } from './components/modals/hours-edit-modal/hours-edit-modal.component';
import { PeriodEventDetailsModalComponent } from './components/modals/hours-edit-modal/period-event-details-modal/period-event-details-modal.component';
import { InstagramInputModalComponent } from './components/modals/instagram-input-modal/instagram-input-modal.component';
import { MembershipCardComponent } from './components/modals/membership-card/membership-card.component';
import { MembershipModalComponent } from './components/modals/membership-modal/membership-modal.component';
import { RestaurantModalComponent } from './components/modals/restaurant-modal/restaurant-modal.component';
import { RestaurantSlideGalleryComponent } from './components/modals/restaurant-modal/restaurant-slide-gallery/restaurant-slide-gallery.component';
import { ShareModalComponent } from './components/modals/share-modal/share-modal.component';
import { SubscribeModalComponent } from './components/modals/subscribe-modal/subscribe-modal.component';
import { TopSwipeModalComponent } from './components/modals/top-swipe-modal/top-swipe-modal.component';
import { UsernameChoiceModalComponent } from './components/modals/username-choice-modal/username-choice-modal.component';
import { VideoModalInputComponent } from './components/modals/video-modal/video-modal-input/video-modal-input.component';
import { VideoModalComponent } from './components/modals/video-modal/video-modal.component';
import { NavbarBottomExploreComponent } from './components/navbar/navbar-bottom-explore/navbar-bottom-explore.component';
import { NavbarBottomMenusCsComponent } from './components/navbar/navbar-bottom-menus-cs/navbar-bottom-menus-cs.component';
import { NavbarBottomMenusComponent } from './components/navbar/navbar-bottom-menus/navbar-bottom-menus.component';
import { NavbarBottomUserComponent } from './components/navbar/navbar-bottom-user/navbar-bottom-user.component';
import { NavbarBottomIconExploreComponent } from './components/navbar/navbar-bottom/navbar-bottom-icon-explore/navbar-bottom-icon-explore.component';
import { NavbarBottomIconFeedComponent } from './components/navbar/navbar-bottom/navbar-bottom-icon-feed/navbar-bottom-icon-feed.component';
import { NavbarBottomIconRecentComponent } from './components/navbar/navbar-bottom/navbar-bottom-icon-recent/navbar-bottom-icon-recent.component';
import { NavbarBottomIconUserComponent } from './components/navbar/navbar-bottom/navbar-bottom-icon-user/navbar-bottom-icon-user.component';
import { NavbarBottomSemicircleNotchComponent } from './components/navbar/navbar-bottom/navbar-bottom-semicircle-notch/navbar-bottom-semicircle-notch.component';
import { NavbarBottomComponent } from './components/navbar/navbar-bottom/navbar-bottom.component';
import { NavbarTopExploreComponent } from './components/navbar/navbar-top-explore/navbar-top-explore.component';
import { NavbarTopFoodGalleryComponent } from './components/navbar/navbar-top-food-gallery/navbar-top-food-gallery.component';
import { NavbarTopGemsComponent } from './components/navbar/navbar-top-gems/navbar-top-gems.component';
import { NavbarTopMenuCsComponent } from './components/navbar/navbar-top-menu-cs/navbar-top-menu-cs.component';
import { NavbarTopMenuComponent } from './components/navbar/navbar-top-menu/navbar-top-menu.component';
import { NavbarTopPeriodEventDetailsComponent } from './components/navbar/navbar-top-period-event-details/navbar-top-period-event-details.component';
import { NavbarTopPostNewComponent } from './components/navbar/navbar-top-post-new/navbar-top-post-new.component';
import { NavbarTopPostComponent } from './components/navbar/navbar-top-post/navbar-top-post.component';
import { NavbarTopProfileComponent } from './components/navbar/navbar-top-profile/navbar-top-profile.component';
import { NavbarTopSettingsComponent } from './components/navbar/navbar-top-settings/navbar-top-settings.component';
import { NavbarTopComponent } from './components/navbar/navbar-top/navbar-top.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FeedComponent } from './components/post-shared/feed/feed.component';
import { PostNewComponent } from './components/post-shared/post-new/post-new.component';
import { PostDesktopComponent } from './components/post-shared/posts-desktop/post-desktop.component';
import { PostsComponent } from './components/post-shared/posts/posts.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ResetComponent } from './components/reset/reset.component';
import { ClassicFoodControlsComponent } from './components/restaurant/classic-food-controls/classic-food-controls.component';
import { SectionButtonsComponent } from './components/restaurant/classic-food-controls/section-view/section-buttons/section-buttons.component';
import { SectionCheckboxComponent } from './components/restaurant/classic-food-controls/section-view/section-checkbox/section-checkbox.component';
import { SectionRadioComponent } from './components/restaurant/classic-food-controls/section-view/section-radio/section-radio.component';
import { SectionViewComponent } from './components/restaurant/classic-food-controls/section-view/section-view.component';
import { ClassicFoodImageComponent } from './components/restaurant/classic-food-image/classic-food-image.component';
import { InfoBarComponent } from './components/restaurant/info-bar/info-bar.component';
import { FoodMenusCsComponent } from './components/restaurant/menu-router/food-menus-cs/food-menus-cs.component';
import { AdditionsCsModalComponent } from './components/restaurant/menu-router/menu-cs/additions-cs-modal/additions-cs-modal.component';
import { MenuListComponent } from './components/restaurant/menu-router/menu-cs/additions-cs-modal/menu-list/menu-list.component';
import { OptionsCsComponent } from './components/restaurant/menu-router/menu-cs/additions-cs-modal/options-cs/options-cs.component';
import { MenuRouterComponent } from './components/restaurant/menu-router/menu-router.component';
import { AddCategoryButtonComponent } from './components/restaurant/menu-router/menu/add-category-button/add-category-button.component';
import { AllergensModalComponent } from './components/restaurant/menu-router/menu/classic-food/allergens-modal/allergens-modal.component';
import { AllergensComponent } from './components/restaurant/menu-router/menu/classic-food/allergens/allergens.component';
import { ClassicFoodComponent } from './components/restaurant/menu-router/menu/classic-food/classic-food.component';
import { InstructionsComponent } from './components/restaurant/menu-router/menu/classic-food/instructions/instructions.component';
import { ClassicPostImageComponent } from './components/restaurant/menu-router/menu/food-gallery/classic-post-image/classic-post-image.component';
import { FoodGalleryComponent } from './components/restaurant/menu-router/menu/food-gallery/food-gallery.component';
import { GalleryVoteComponent } from './components/restaurant/menu-router/menu/food-gallery/gallery-vote/gallery-vote.component';
import { MenuLoaderComponent } from './components/restaurant/menu-router/menu/menu-loader/menu-loader.component';
import { MenuComponent } from './components/restaurant/menu-router/menu/menu.component';
import { QrModalComponent } from './components/restaurant/menu-router/qr-modal/qr-modal.component';
import { RestaurantDesktopComponent } from './components/restaurant/restaurant-desktop/restaurant-desktop.component';
import { RestaurantFoodImageComponent } from './components/restaurant/restaurant-desktop/restaurant-food-image/restaurant-food-image.component';
import { RestaurantLoaderComponent } from './components/restaurant/restaurant-desktop/restaurant-loader/restaurant-loader.component';
import { RestaurantHoursComponent } from './components/restaurant/restaurant-hours/restaurant-hours.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { HeroComponent } from './components/settings/hero/hero.component';
import { PasswordComponent } from './components/settings/password/password.component';
import { ProfileComponent } from './components/settings/profile/profile.component';
import { SettingsComponent } from './components/settings/settings/settings.component';
import { SubscriptionComponent } from './components/settings/subscription/subscription.component';
import { TargetsComponent } from './components/targets/targets.component';
import { TermsComponent } from './components/terms/terms.component';
import { MembershipDesktopComponent } from './components/user-authorization/membership/membership-desktop.component';
import { UserAuthorizationComponent } from './components/user-authorization/user-authorization.component';
import { UserAvatarComponent } from './components/user/user-avatar/user-avatar.component';
import { UserLoaderComponent } from './components/user/user-loader/user-loader.component';
import { UserComponent } from './components/user/user.component';
import { VerifyOwnershipComponent } from './components/verify-ownership/verify-ownership.component';
import { GlobalErrorHandler } from './helpers/global-error.handler';
import { HttpResponseInterceptor } from './helpers/http-response.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { NgbTimeModelAdapter } from './helpers/timepicker-adapter';
import { DirectiveModule } from './modules/directive.module';
import { PipeModule } from './modules/pipe.module';
import { SegmentedButtonComponent } from './segmented-button/segmented-button.component';
import { DecoratorInjectorService } from './services/decorator/decorator-injector.service';
import { NgbDateCustomParserFormatter } from './services/ngb-date-custom-parser-formatter';

export async function provideSwal() {
  return Swal.mixin({
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-lg btn-primary',
      cancelButton: 'btn btn-lg btn-default'
    }
  });
}

@Injectable()
export class MHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {direction: Hammer.DIRECTION_ALL},
  } as any;
}

const facebookLoginOptions = {
  scope: 'public_profile,email',
  return_scopes: true,
  enable_profile_selector: true
};

const googleLoginOptions = {
  scopes: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid'
};

@NgModule({
  declarations: [
    AboutRestaurantComponent,
    AddCategoryButtonComponent,
    AdditionsCsModalComponent,
    AdditionsEditModalComponent,
    AddToHomeScreenModalComponent,
    AdminComponent,
    AllergensComponent,
    AllergensModalComponent,
    AppComponent,
    BasketComponent,
    BasketModalComponent,
    BrowserSupportComponent,
    CategoryEditComponent,
    ChainModalDesktopComponent,
    CheckoutComponent,
    ClassicFoodComponent,
    ClassicFoodControlsComponent,
    ClassicFoodImageComponent,
    ClassicPostImageComponent,
    DashboardComponent,
    DesktopModalSidenavComponent,
    DineInModalComponent,
    DistanceFilterComponent,
    DividerMobileModalComponent,
    DividerModalComponent,
    DivRectangleComponent,
    EmailModalComponent,
    EmbedVideoComponent,
    ExploreComponent,
    FavoriteFoodImageComponent,
    FavoritesComponent,
    FavoritesListComponent,
    FeedComponent,
    FlashReceiptComponent,
    FoodGalleryComponent,
    FoodImgComponent,
    FoodMenusCsComponent,
    GalleryVoteComponent,
    HeartComponent,
    HeroComponent,
    HomeComponent,
    HoursEditModalComponent,
    ImageCropperComponent,
    ImagePickerComponent,
    ImageSelectModalComponent,
    InfoBarComponent,
    InstagramComponent,
    InstagramHelperComponent,
    InstagramInputModalComponent,
    InstructionsComponent,
    LayoutAdminComponent,
    LayoutAdminLocationComponent,
    LayoutAdminWithoutLocationComponent,
    LayoutEntityComponent,
    LayoutFoodComponent,
    LayoutFrontComponent,
    LayoutMainComponent,
    LayoutMenuComponent,
    LayoutRestaurantComponent,
    LayoutRestaurantLocationComponent,
    LayoutRestaurantNoLocationComponent,
    LayoutSettingsComponent,
    LayoutSidenavLeftComponent,
    LayoutSidenavRightComponent,
    LayoutUserComponent,
    LikeComponent,
    LocationAddModalComponent,
    LocationDeleteModalComponent,
    MembershipCardComponent,
    MembershipDesktopComponent,
    MembershipModalComponent,
    MenuComponent,
    MenuListComponent,
    MenuLoaderComponent,
    MenuRouterComponent,
    MenuTableComponent,
    MiniContactComponent,
    NavbarBottomComponent,
    NavbarBottomExploreComponent,
    NavbarBottomIconExploreComponent,
    NavbarBottomIconFeedComponent,
    NavbarBottomIconRecentComponent,
    NavbarBottomIconUserComponent,
    NavbarBottomMenusComponent,
    NavbarBottomMenusCsComponent,
    NavbarBottomSemicircleNotchComponent,
    NavbarBottomUserComponent,
    NavbarTopComponent,
    NavbarTopExploreComponent,
    NavbarTopFoodGalleryComponent,
    NavbarTopGemsComponent,
    NavbarTopMenuComponent,
    NavbarTopMenuCsComponent,
    NavbarTopPeriodEventDetailsComponent,
    NavbarTopPostComponent,
    NavbarTopPostNewComponent,
    NavbarTopProfileComponent,
    NavbarTopSettingsComponent,
    OptionsCsComponent,
    OptionsEditComponent,
    OrdersComponent,
    OrdersModalComponent,
    OwnerVerificationComponent,
    PageNotFoundComponent,
    PasswordComponent,
    PeriodEventDetailsModalComponent,
    PhantomComponent,
    PhoneModalComponent,
    PickerComponent,
    PickerModalComponent,
    PickUpModalComponent,
    PlansComponent,
    PostDesktopComponent,
    PostNewComponent,
    PostOptionsComponent,
    PostsComponent,
    PreviewModalComponent,
    PreviewModalFooterComponent,
    PreviewModalSlideComponent,
    PrivacyComponent,
    ProfileComponent,
    QrModalComponent,
    ReceiptModalComponent,
    ReportVideoComponent,
    RestaurantFoodImageComponent,
    ResetComponent,
    RestaurantAddModalComponent,
    RestaurantComponent,
    RestaurantDesktopComponent,
    RestaurantEditComponent,
    RestaurantEditModalComponent,
    RestaurantHoursComponent,
    RestaurantImgComponent,
    RestaurantLoaderComponent,
    RestaurantModalComponent,
    RestaurantSlideGalleryComponent,
    RestaurantsPickerComponent,
    SearchCityComponent,
    SearchCityInputComponent,
    SearchCityModalComponent,
    SearchDesktopComponent,
    SearchDesktopLoaderComponent,
    SearchFiltersComponent,
    SearchMobileComponent,
    SearchMobileLoaderComponent,
    SearchModalComponent,
    SearchModalFavoritesNavbarTopComponent,
    SearchModalFavoritesSlideComponent,
    SearchModalFooterComponent,
    SearchRestaurantListComponent,
    SectionButtonsComponent,
    SectionCheckboxComponent,
    SectionRadioComponent,
    SectionViewComponent,
    SettingsComponent,
    ShareModalComponent,
    SubscribeModalComponent,
    SubscriptionComponent,
    TagsEditComponent,
    TaxFeeModalComponent,
    TermsComponent,
    TopSwipeModalComponent,
    UserAuthorizationComponent,
    UserAvatarComponent,
    UserComponent,
    UserLoaderComponent,
    UsernameChoiceModalComponent,
    VerifyOwnershipComponent,
    VideoModalComponent,
    VideoReportModalComponent,
    SearchVideoModalComponent,
    TargetsComponent,
    SegmentedButtonComponent,
    VideoModalInputComponent,
    VideoDesktopModalComponent,
    SearchDesktopTopnavComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DirectiveModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    InfiniteScrollModule,
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.TRACE : NgxLoggerLevel.OFF,
      enableSourceMaps: true
    }),
    NgbModule,
    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxSkeletonLoaderModule,
    NouisliderModule,
    PipeModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:100'
    }),
    SidenavModule,
    SimplebarAngularModule,
    SocialLoginModule,
    SortablejsModule,
    SweetAlert2Module.forRoot({provideSwal}),
    SwiperModule,
    ToastrModule.forRoot({preventDuplicates: true}),
  ],
  providers: [
    CookieService,
    DecoratorInjectorService,
    provideNgxMask(),
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: HAMMER_GESTURE_CONFIG, useClass: MHammerConfig},
    {provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    {provide: NgbTimeAdapter, useClass: NgbTimeModelAdapter},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId, facebookLoginOptions)
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, googleLoginOptions)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static decoratorInjector: DecoratorInjectorService;

  public constructor(decoratorInjector: DecoratorInjectorService) {
    AppModule.decoratorInjector = decoratorInjector;
  }
}
